import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from '../Pages/Login/Login'
import Frontpage from '../Pages/Frontpage/Frontpage'
import Search from '../Pages/Search/Search'
import History from '../Pages/History/Histroy'
import NewClient from '../Pages/NewClient/NewClient'
import NewAPV from '../Pages/APV/NewAPV'
import PreAPV from '../Pages/APV/PreAPV'
import ClientLookup from '../Pages/ClientLookup/ClientLookup'
import EditAPV from '../Pages/APV/EditAPV'
import ClientPage from '../Pages/ClientPage/ClientPage'
import Handleplan from '../Pages/Handleplan/Handleplan'
import ReadOnlyAPV from '../Pages/APV/ReadOnlyAPV'
import PrintNewAPV from '../Pages/APV/PrintNewAPV'
import ResetPassword from '../Components/ResetPassword/ResetPassword'

function Approuter() {
  return (
    <Routes>
        <Route index element={<Login />}></Route>
        <Route path='/reset-password/' element={<ResetPassword />}></Route>
        { sessionStorage.getItem('user') ? <>
        <Route path='/dashboard' element={<Frontpage />}></Route>
        <Route path='/search' element={<Search />}></Route>
        <Route path='/history' element={<History />}></Route>
        <Route path='/newclient' element={<NewClient />}></Route>
        <Route path='/newapv/id/:id' element={<NewAPV />}></Route>
        <Route path='/newapv1' element={<PreAPV />}></Route>
        <Route path='/readapv/:id' element={<ReadOnlyAPV />}></Route>
        <Route path='/printnewapv' element={<PrintNewAPV />}></Route>
        <Route path='/editapv/:id' element={<EditAPV />}></Route>
        <Route path='/clientlookup/:id' element={<ClientLookup />}></Route>
        <Route path='/clientpage/:id' element={<ClientPage />}></Route>
        <Route path='/handleplaner/:id' element={<Handleplan />}></Route>
        </>: null}
    </Routes>
  )
}

export default Approuter