import React, { useEffect, useState } from 'react';
import { NewAPVStyle } from './NewAPV.style';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios'; // Import axios for making HTTP requests
import SuccesCheckmark from '../../Components/SuccesCheckmark/SuccesCheckmark';
import printImg from "../../Assets/9004704_printer_print_printing_office_work_icon.svg"
import deleteImg from "../../Assets/8664938_trash_can_delete_remove_icon.svg"
import handleplanImg from "../../Assets/2093653_check_checklist_list_mark_ok_icon.svg"
import saveImg from "../../Assets/8666542_save_icon.svg"
function NewAPV() {
  const { id } = useParams()
  const navigate = useNavigate();
  const [num, setNum] = useState(0);
  const [newQuestion, setNewQuestion] = useState(null);
  const [textInput, setTextInput] = useState('');
  const [handleplanShow, setHandleplanShow] = useState([null,null]);
  const [translateError, setTranslateError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [language, setLanguage] = useState("da");
  const [prevLanguage, setPrevLanguage] = useState("da");
  const [hoverIndex, setHoverIndex] = useState(null);
  const [apiData, setApiData] = useState();
  const [isPrint, setIsPrint] = useState(false);
  const [data, setData] = useState(JSON.parse(sessionStorage.getItem('apv'))?.APV || []);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const addNewQuestion = (index) => {
    data[index].questions.push({ 
      question: textInput, 
      status: '', 
      deadline: "", 
      explanation: "", 
      problem: "", 
      responsible: ""});
    setNum(num + 1);
    setNewQuestion(null);
  };

  const deleteQuestion = (sectionIndex, questionIndex) => {
    const newData = [...data];
    newData[sectionIndex].questions.splice(questionIndex, 1);
    setData(newData);
  };

  const addStatus = (sectionIndex, questionIndex, status) => {
    const newData = [...data];
    newData[sectionIndex].questions[questionIndex].status = status;
    setData(newData);
    if (status === "Udfordring") {
          setHandleplanShow([sectionIndex, questionIndex])
    }

  };

  const addDeadline = (sectionIndex, questionIndex, deadline) => {
    const newData = [...data];
    newData[sectionIndex].questions[questionIndex].deadline = deadline;
    setData(newData);

  };

  const addExplantion = (sectionIndex, questionIndex, explanation) => {
    const newData = [...data];
    newData[sectionIndex].questions[questionIndex].explanation = explanation;
    setData(newData);

  };

  const addResponsible = (sectionIndex, questionIndex, responsible) => {
    const newData = [...data];
    newData[sectionIndex].questions[questionIndex].responsible = responsible;
    setData(newData);

  };

  useEffect(() => {
    const h2Elements = Array.from(document.querySelectorAll('h2'));
    const optionElements = h2Elements.map((item, index) => (
      <p key={index} onClick={() => {document.getElementById(item.innerText.replace(' ', '-')).scrollIntoView({ behavior: 'smooth' }); setModalOpen(false)}}>{item.innerText}</p>
    ));
    setOptions(optionElements);
  }, [data]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.kvægapv.eu/getAllFarmers`);
        setApiData(response.data);
        //console.log(response.data);
      } catch (error) {
        //console.log(error);
      }
    };
    fetchData();
    
    // Cleanup function
    return () => {
    
    };
  }, []);
  const translateText = async (text) => {
    try {
      const response = await axios.post('http://localhost:3001/translate', { text, targetLanguage: language, startLanguage: prevLanguage });
      return response.data.translatedText;
    } catch (error) {
      console.error('Translation error:', error);
      throw error;
    }
  };

  const deleteAPV = async (apvId, apvName) => {
    console.log(apvId);
    try {
      // Ask for user confirmation
      const isSure = window.confirm(`Er du sikker på at du vil slette denne APV?`);
  
      // Proceed only if the user confirms
      if (isSure) {
        const response = await axios.post(`https://api.kvægapv.eu/delAPV`, {
          id: apvId,
        });
  
        // Handle successful response
      } else {
        // Handle the case where the user cancels the action
      }
    } catch (error) {
      console.error('Send login error:', error);
      throw error;
    }
  };
console.log(data);
  const updateAPV = async (id,content) => {
    console.log(id);
    try {
        const response = await axios.put(`https://api.kvægapv.eu/updateapv/${id}`, {
            content: JSON.stringify(content), // Ensure content is stringified
        });
        //console.log('APV updated successfully:', response.data);
              setTimeout(() => {
        setSuccess(true); // After 1 second, set success to true
        setTimeout(() => {
          setSuccess(false); // After 1 second, set success to false
        }, 1500);
      }, 200);

    } catch (error) {
        console.error('Update error:', error);
        throw error;
    }
};
  const translateAndAddQuestion = async (index) => {
    try {
      const translatedQuestion = await translateText(textInput);
      data[index].questions.push({ 
        question: translatedQuestion, 
        status: '', 
        deadline: "", 
        explanation: "", 
        problem: "", 
        responsible: "" 
      });
      setNum(num + 1);
      setNewQuestion(null);
    } catch (error) {
      // Handle translation error
      console.error('Translation error:', error);
      setTranslateError(true)
    }
  };
//console.log(data);
  const translateWholePage = async () => {
    try {
      const textElements = Array.from(document.querySelectorAll('p, h1, h2, h3, h4, h5, h6, button'));
      for (let i = 0; i < textElements.length; i++) {
        const text = textElements[i].innerText;
        const translatedText = await translateText(text);
        textElements[i].innerText = translatedText;
      }
    } catch (error) {
      console.error('Translation error:', error);
    }
  };
//console.log(data);
const handleUpdate = () => {
  updateAPV(id,data)
  setHandleplanShow([null,null])
}
  return (
    <NewAPVStyle>
      {success && <SuccesCheckmark />}
      {!data && <h2 style={{position: "absolute", left: "50vw", top: "40vh", right: "50vh"}}>Gør tingene klar...</h2>}
      <div className='btnDiv2'>
              <span id='theTop'></span>
      <button id='toTop' onClick={() => document.getElementById('theTop').scrollIntoView({ behavior: 'smooth' })}>⬆️</button>
      <button id='modalBtn' onClick={() => setModalOpen(modalOpen ? false : true)}>{'Hop til'}</button>
      {/* <button onClick={translateWholePage}>Translate Page</button>
      <select onChange={(e) => {
        setPrevLanguage(language)
        setLanguage(e.target.value)
        
        }} name="" id="">
        <option value="da">Dansk</option>
        <option value="pl">Polsk</option>
        <option value="es">Spansk</option>
        <option value="fr">Fransk</option>
        <option value="en">Engelsk</option>
        <option value="uk">Ukrainsk</option>
      </select> */}

      </div>

      
      {modalOpen && (
        <div className="modal">
          <div className="modal-content">
            {options}
          </div>
        </div>
      )}
 
     { !isDesktop ? <section key={num} className='mobileForm'>
        {data.map((item, index) => (
          <React.Fragment key={index}>
            <h2 id={item.heading.replace(' ', '-')}>{item.heading}</h2>
            {item.questions.map((question, qIndex) => (
              <div key={qIndex}>
                <p onClick={() => deleteQuestion(index, qIndex)}>X</p>
                <p>{question.question}</p>
                <button
                  style={{ backgroundColor: question.status === 'Udfordring' ? 'red' : 'initial' }}
                  onClick={() => addStatus(index, qIndex, 'Udfordring')}
                >
                  Challange
                </button>
                <button
                  style={{ backgroundColor: question.status === 'I orden' ? 'green' : 'initial' }}
                  onClick={() => addStatus(index, qIndex, 'I orden')}
                >
                  No problem
                </button>
                <button
                  style={{ backgroundColor: question.status === 'Irrelevant' ? 'gray' : 'initial' }}
                  onClick={() => addStatus(index, qIndex, 'Irrelevant')}
                >
                  Irrelevant
                </button>
              </div>
            ))}
            {newQuestion === null ? (
              <button onClick={() => setNewQuestion(index)}>Nyt spørgsmål</button>
            ) : null}
            {newQuestion === index ? (
              <div>
                <input type="text" onChange={(e) => setTextInput(e.target.value)} />
                <button onClick={() => translateAndAddQuestion(index)}>Tilføj</button>
                <button onClick={() => setNewQuestion(null)}>Annuller</button>
              </div>
            ) : null}
          </React.Fragment>
        ))}
      </section>
      :
<section key={num} className='desktopForm'>
  {data.map((item, index) => (
    <React.Fragment key={index}>
      <div className='overheadingDiv'>
        {JSON.parse(sessionStorage.getItem('overheadings'))[index] === JSON.parse(sessionStorage.getItem('overheadings'))[index - 1] ? null : <h3>{JSON.parse(sessionStorage.getItem('overheadings'))[index]}</h3>}
      </div>
      
      <div onMouseOver={() => setHoverIndex(index)} onMouseLeave={() => setHoverIndex(null)}>
        <div className='categoryDiv' style={{display: data[index].questions.length > 0 ? "grid" : "none"}}>
          <h2 id={item.heading.replace(' ', '-')} style={{backgroundColor: "#D9D9D9", borderLeft: "solid black 1px"}}>{item.heading}</h2>
          <p style={{backgroundColor: "#CF4040"}} onClick={() => {
            item.questions.map((question, qIndex) => {
              addStatus(index, qIndex, 'Udfordring')
            })
          }}>Udfordring</p>
          <p style={{backgroundColor: "#28C253"}} onClick={() => {
            item.questions.map((question, qIndex) => {
              addStatus(index, qIndex, 'I orden')
            })
          }}>I orden</p>
          <p style={{backgroundColor: "#B8B8B8"}} onClick={() => {
            item.questions.map((question, qIndex) => {
              addStatus(index, qIndex, 'Irrelevant')
            })
          }}>Irrelevant</p>
        </div>
        {item.questions.map((question, qIndex) => (
          <React.Fragment key={`${index}-${qIndex}`}>
            <article className='headingArt'>
            <p style={{paddingTop: "1vh"}} onClick={() => deleteQuestion(index, qIndex)}>{isPrint ? " " : "X"}</p>
              <div>
                <span><p>{question.question}</p><p>{isPrint ? " " : "?"}</p></span>
              </div>
              <div style={{borderRight: "solid black 1px", borderLeft: "solid black 1px"}} onClick={() => addStatus(index, qIndex, 'Udfordring')}>{question.status === "Udfordring" ? "x" : ""}</div>
              <div style={{borderRight: "solid black 1px"}} onClick={() => addStatus(index, qIndex, 'I orden')}>{question.status === "I orden" ? "x" : ""}</div>
              <div onClick={() => addStatus(index, qIndex, 'Irrelevant')}>{question.status === "Irrelevant" ? "x" : ""}</div>
            </article>
            <article className='handleplanArt' style={{display: index === handleplanShow[0] && qIndex === handleplanShow[1] ? "grid" : "none"}}>
              <div>
                <h3>Beskriv problemet</h3>
                <textarea name="" id="" cols="30" rows="5" onChange={(e) => addExplantion(index, qIndex, e.target.value)}></textarea>
              </div>
              <div className='deadlineDiv'>
                <h3>Deadline</h3>
                <div className='deadlineInputDiv'>
                  <label htmlFor="">Løses straks</label><input type="radio" name="" id="" onChange={(e) => addDeadline(index, qIndex, new Date().toLocaleDateString('en-GB'))}/> 
                  <label htmlFor="">Løses inden </label><input type="date" name="" id="" onChange={(e) => addDeadline(index, qIndex, new Date(e.target.value).toLocaleDateString('en-GB'))} />
                </div>
              </div>
              <div>
                <h3>Ansvarlig</h3>
                <input placeholder='Ansvarlig' type="text" onChange={(e) => addResponsible(index, qIndex, e.target.value)}/>
              </div>
              <div>
                <button onClick={() => {
                  updateAPV(id,data)
                  setHandleplanShow([null,null])
                }}>Gem</button>
                <button onClick={() => setHandleplanShow([null,null])}>Senere</button>
              </div>
            </article>
          </React.Fragment>
        ))}
        {hoverIndex === index ? (
          <button className='newQuestion' onClick={() => setNewQuestion(index)}>Nyt spørgsmål</button>
        ) : null}
        {newQuestion === index ? (
          <article key={`new-${index}`} className='headingArt'>
            <p onClick={() => deleteQuestion(index, item.questions.length)}>X</p>
            <div>
              <input
                type="text"
                placeholder='Skriv nyt spørgsmål her...'
                value={textInput}
                onChange={(e) => setTextInput(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    addNewQuestion(index);
                    // if (language === "en") {
                    //   addNewQuestion(index);
                    // } else {
                    //   setTimeout(() => {
                    //     if (translateError) {
                    //     addNewQuestion(index);
                    //     setTranslateError(false);
                    //   } else {
                    //     translateAndAddQuestion(index);
                    //   }
                    //   }, 500);
                      
                    // }
                    setNewQuestion(null);
                    setTextInput("");
                  }
                }}
              />
            </div>
            <div onClick={() => addStatus(index, item.questions.length, 'Udfordring')}></div>
            <div onClick={() => addStatus(index, item.questions.length, 'I orden')}></div>
            <div onClick={() => addStatus(index, item.questions.length, 'Irrelevant')}></div>
          </article>
        ) : null}
      </div>
      
    </React.Fragment>
  ))}
</section>


}
<div className='btnDiv' style={{display: isDesktop ? "grid" : "grid"}}>
        <button onClick={() => updateAPV(id,data)}> <img src={saveImg} alt="gem" /> </button>
        <button onClick={() => deleteAPV(id)}> <img src={deleteImg} alt="Slet" /> </button>
        <button onClick={() => {
        document.getElementsByClassName('btnDiv2')[0].style.display = "none"
        document.getElementsByClassName('btnDiv')[0].style.display = "none"
        document.getElementsByTagName('header')[0].style.display = "none"
        document.getElementsByClassName('desktopForm')[0].style.width = "90vw"
document.getElementsByClassName('desktopForm')[0].style.marginLeft = "0vw"
        document.getElementsByClassName('desktopForm')[0].style.marginTop = "2vw"
        for (let index = 0; index < document.getElementsByClassName('headingArt').length; index++) {
          document.getElementsByClassName('headingArt')[index].style.gridTemplateColumns = "5% 55% 13% 13% 13%";
          
        }
        setTimeout(() => {
          window.print() 
        }, 200);
       
        setTimeout(() => {
          document.getElementsByTagName('h3')[0].style.display = "none"
          document.getElementsByClassName('btnDiv2')[0].style.display = "block"
          document.getElementsByClassName('btnDiv')[0].style.display = "grid"
          document.getElementsByTagName('header')[0].style.display = "block"
          document.getElementsByClassName('desktopForm')[0].style.width = "80vw"
          document.getElementsByClassName('desktopForm')[0].style.marginLeft = "12.5rem"
          for (let index = 0; index < document.getElementsByClassName('headingArt').length; index++) {
            document.getElementsByClassName('headingArt')[index].style.gridTemplateColumns = "10% 50% 13% 13% 13%";
            
          }
        }, 200);
      }}> <img src={printImg} alt="Print" /> </button>
        <button onClick={() => {
          handleUpdate()
          setTimeout(() => {
             navigate(`/handleplaner/${id}`)
          }, 500);
         }}> <img src={handleplanImg} alt="handleplaner" /> </button>
      </div>
    </NewAPVStyle>
  );
}

export default NewAPV;
