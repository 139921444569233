import React, { useEffect, useState } from 'react';
import { ClientLookupStyle } from './ClientLookup.style';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import SuccesCheckmark from '../../Components/SuccesCheckmark/SuccesCheckmark';
import Spinner from '../../Components/Spinner/Spinner';

function ClientLookup() {
  const { id } = useParams()
  const { register, handleSubmit, formState: { errors } } = useForm()
  const navigate = useNavigate(); // Get the navigation function
  const [client, setClient] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [edit, setEdit] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);
  const [isProblem, setIsProblem] = useState(true);
  const [data, setData] = useState("");
  const [token, setToken] = useState(null);
  const [apvs, setApvs] = useState("");
  const [problems, setProblems] = useState([]);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageChange, setPageChange] = useState(0);



console.log(sessionStorage.getItem(`tempPass${data.cvr}`));
  const [solved, setSolved] = useState([]);
  
  function splitFullName(fullName) {
    const parts = fullName.split(' ');
    let firstName = parts[0];
    let middleName = '';
    let lastName = '';

    // If there are more than two parts, assume the last part as the last name
    if (parts.length > 1) {
        lastName = parts.pop(); // Get the last part as last name
        // If there are still more parts, assume the first part as the first name
        if (parts.length > 1) {
            firstName = parts.shift(); // Get the first part as first name
            // If there are more parts remaining, they are middle names
            middleName = parts.join(' '); // Join the remaining parts as middle name
        }
    }

    return {
        firstName: firstName,
        middleName: middleName,
        lastName: lastName
    };
}


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.kvægapv.eu/getFarmer/${id}`);
        const response1 = await axios.get(`https://api.kvægapv.eu/getSomeApv?id=${id}`);
        const response4 = await axios.get(`https://api.kvægapv.eu/getBilag/${id}`);
        setData(response.data);
        setApvs(response1.data);
        setBilag(response4.data.allBilag.reverse())

        console.log(response4.data.allBilag);
      } catch (error) {
        console.log(error);
      }

      try {
        const response2 = await axios.get(`https://api.kvægapv.eu/latestChallenges/${id}`);
        const response3 = await axios.get(`https://api.kvægapv.eu/latestSolved/${id}`);

        setProblems(response2.data)
        setSolved(response3.data)
        console.log(response2.data);
        console.log(response3.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response4 = await axios.get(`https://api.kvægapv.eu/getBilag/${id}`);
        setBilag(response4.data.allBilag.reverse())
        console.log(response4.data)
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [pageChange])
  const submitForm = (formData, event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    const nameParts = splitFullName(client);
    const firstName = nameParts.firstName;
    const middleName = nameParts.middleName;
    const lastName = nameParts.lastName;
    console.log(email, firstName, middleName, lastName, formData.cvr, phone);
    console.log(formData, client, email, phone);
    updateClient(email,firstName,middleName,lastName,formData.cvr,phone, data)
    setTimeout(() => {
     setPageChange(pageChange + 1) 
    }, 200);
    
}
    const [bilag, setBilag] = useState([
      "Gift.pdf",
      "GammelAPV.dosx"
    ])

    let APVs = [
      "APV1",
      "APV2",
      "APV3"
    ]
    const updateClient = async (email, firstname, middlename, lastname, cvr, phone, existingClient) => {
      console.log(id);
  
      // Compare the incoming values with the existing values
      console.log(existingClient);
      
      const hasChanges = (
          email !== "" ||
          firstname !== "" ||
          middlename !== "" ||
          lastname !== "" ||
          cvr !== "" ||
          phone !== ""
      );
  
      // If nothing has changed, exit the function
      if (!hasChanges) {
          console.log('No changes detected. Update skipped.');
          return;
      }
  
      try {
          // Perform the update if there are changes
          const response = await axios.put(`https://api.kvægapv.eu/update-client/${id}`, {
              email: email,
              firstname: firstname,
              middlename: middlename,
              lastname: lastname,
              cvr: cvr,
              phone: phone,
          });
          console.log('Client updated successfully:', response.data);
  
          // Trigger the success messages after 200ms, then hide it after 1500ms
          setTimeout(() => {
              setSuccess(true); // After 200ms, set success to true
              setTimeout(() => {
                  setSuccess(false); // After 1500ms, set success to false
              }, 1500);
          }, 200);
  
      } catch (error) {
          console.error('Update error:', error);
          throw error;
      }
  };
  

  const sendLogin = async (email,clientId) => {
    console.log(id);
    try {
        const response = await axios.post(`https://api.kvægapv.eu/send-farmer-login`, {
          email: email,
          clientId:clientId,
          password: JSON.parse(sessionStorage.getItem(`tempPass${data.cvr}`))
        });
        //console.log('APV updated successfully:', response.data);
        setTimeout(() => {
          setSuccess(true); // After 1 second, set success to true
          setTimeout(() => {
            setSuccess(false); // After 1 second, set success to false
          }, 1500);
        }, 200);

    } catch (error) {
        console.error('send login error:', error);
        throw error;
    }
};

const deleteFarmer = async (clientId) => {
  console.log(clientId);
  try {
    // Ask for user confirmation
    const isSure = window.confirm("Er du sikker på at du vil slette denne landmand?");

    // Proceed only if the user confirms
    if (isSure) {
      const response = await axios.post(`https://api.kvægapv.eu/delFarmer`, {
        id: clientId,
      });

      // Handle successful response
      setTimeout(() => {
        setSuccess(true); // After 1 second, set success to true
        setTimeout(() => {
          setSuccess(false); // After 1 second, set success to false
        }, 1500);
      }, 200);
      setTimeout(() => {
       navigate('/dashboard'); 
      }, 2000);
      
    } else {
      // Handle the case where the user cancels the action
    }
  } catch (error) {
    console.error('Send login error:', error);
    throw error;
  }
};

const deleteAPV = async (apvId, apvName) => {
  console.log(apvId);
  try {
    // Ask for user confirmation
    const isSure = window.confirm(`Er du sikker på at du vil slette denne APV ved navn: ${apvName}?`);

    // Proceed only if the user confirms
    if (isSure) {
      const response = await axios.post(`https://api.kvægapv.eu/delAPV`, {
        id: apvId,
      });
      setTimeout(() => {
        setSuccess(true); // After 1 second, set success to true
        setTimeout(() => {
          setSuccess(false); // After 1 second, set success to false
        }, 1500);
      }, 200);
      // Handle successful response
    } else {
      // Handle the case where the user cancels the action
    }
  } catch (error) {
    console.error('Send login error:', error);
    throw error;
  }
};


  const updateSolved = async (content) => {
    try {
      const response = await axios.put(`https://api.kvægapv.eu/updateLatestSolved/${id}`, { explanation: content });
     
        setTimeout(() => {
          setSuccess(true); // After 1 second, set success to true
          setTimeout(() => {
            setSuccess(false); // After 1 second, set success to false
          }, 1500);
        }, 200);
      
      // You can optionally log the response if needed
      // console.log('APV updated successfully:', response.data);
    } catch (error) {
      console.error('Update error:', error);
      throw error;
    }
  };
  
  const moveToSolved = (index) => {
    const updatedProblems = [...problems];
    const movedItem = updatedProblems.splice(index, 1)[0];
    
    // Assuming movedItem contains the id required for updating the server
    const { id, ...content } = movedItem;
  console.log(movedItem.explanation);
    if (movedItem.explanation !== "") {
      setProblems(updatedProblems);
      console.log(movedItem);
      setSolved([...solved, movedItem]);
       updateSolved(movedItem.explanation);

    }else{
      alert("Du kan ikke flytte en opgave uden text")

    }
    // Pass id and content to updateSolved
  };
  
// Function to handle input changes and sanitize them
const handleClientChange = (event) => {
  // Sanitize input to prevent XSS
  const sanitizedValue = event.target.value.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  setClient(sanitizedValue);
};

const handleEmailChange = (event) => {
  // Sanitize input to prevent XSS
  const sanitizedValue = event.target.value.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  setEmail(sanitizedValue);
};

const handlePhoneChange = (event) => {
  // Sanitize input to prevent XSS
  const sanitizedValue = event.target.value.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  setPhone(sanitizedValue);
};

const handleImageUpload = event => {
  const file = event.target.files[0];
  const folder = "/" + data.id || '/';
  const clientId = id;
  let isDublicate = false
  if (!file) return;
  console.log(file.name);
  console.log(bilag);
  bilag.map((item) => {
    console.log(item);
    
      if (item.filename === file.name) {
    alert("Landmanden har allerede en fil med det navn!")
    isDublicate = true
  }
  })
setTimeout(() => {
  if (!isDublicate) {
    console.log('Uploading file:', file.name, 'to folder:', folder, "ClientID: ", clientId);

  const formData = new FormData();
  formData.append('file', file);

  fetch('https://api.kvægapv.eu/fileUpload', {
    headers: {
      'filename': file.name,
      'targetFolder': folder,
      'clientId': clientId
    },
    method: 'POST',
    body: formData
  })
  .then(response => response.json())
  .then(data => {
    if (data.status === 'success') {
      setTimeout(() => {
        setSuccess(true); // After 1 second, set success to true
        setTimeout(() => {
          setSuccess(false); // After 1 second, set success to false
        }, 1500);
        setPageChange(pageChange + 1) 
      }, 200);

      
    } else {
      alert('Error uploading file: ' + data.description);
    }
  })
  .catch(error => {
    console.error('Upload error:', error);
    alert('Upload failed: ' + error.message);
  });
  }
}, 500);
  
};

const handleFileDownload = (event) => {
  event.preventDefault();
  const filename = event.target.id;
  const folder = "/" + (data.id || '/');
  if (!filename) return;

  console.log('Downloading file:', filename, 'from folder:', folder);

  // Show spinner by setting loading to true
  setLoading(true);

  fetch(`https://api.kvægapv.eu/fileDownload?filename=${encodeURIComponent(filename)}&folder=${encodeURIComponent(folder)}`)
    .then(response => {
      console.log('Fetch response:', response);
      if (response.ok) {
        // alert("Din fil bliver nu hentet, nogle filer kan dog tage længere tid at hente end andre");
        return response.blob();
      } else {
        return response.json().then(data => {
          throw new Error(data.description);
        });
      }
    })
    .then(blob => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
      console.log('File downloaded successfully');
    })
    .catch(error => {
      console.error('Download error:', error);
      alert('Download failed: ' + error.message);
    })
    .finally(() => {
      // Hide spinner once the download is finished (either success or error)
      setLoading(false);
    });
};

const handleFileDelete = (event,index) => {
  event.preventDefault();
  const filename = document.getElementById('bilagItem' + index).innerText;
  const folder = data.id || '/';
  if (!filename) return;
console.log(filename,folder);
  console.log('Deleting file:', filename, 'from folder:', folder);

  fetch(`https://api.kvægapv.eu/fileDelete?filename=${encodeURIComponent(filename)}&clientId=${encodeURIComponent(id)}`, {
    method: 'DELETE'
  })
  .then(response => response.json())
  .then(data => {
    if (data.status === 'success') {
      setTimeout(() => {
        setSuccess(true); // After 1 second, set success to true
        setTimeout(() => {
          setSuccess(false); // After 1 second, set success to false
        }, 1500);
      }, 200);
    } else {
      alert('Error deleting file: ' + data.description);
    }
  })
  .catch(error => {
    console.error('Delete error:', error);
    alert('Delete failed: ' + error.message);
  });
};

console.log(data);
    return (
        <ClientLookupStyle edit={edit}>
          {success && <SuccesCheckmark />}
{   data && <>
 {modalOpen && (
         <div className="modal">
          <button onClick={() => setModalOpen(modalOpen ? false : true)}>X</button>
         <div className="modal-content">
             <h2>{isProblem ? problems[modalIndex].question : solved[modalIndex].question}</h2>
             <div>
              <h4>Problemet</h4>
              <h4>Løses inden</h4>
              <h4>Ansvarlig</h4>
              <p>{isProblem ? problems[modalIndex].explanation : solved[modalIndex].explanation} </p>
              <p>{isProblem ? problems[modalIndex].deadline : solved[modalIndex].deadline}</p>
              <p>{isProblem ? problems[modalIndex].responsible : solved[modalIndex].responsible}</p>
             </div>
         </div>
       </div>
 )}

         {!edit ? <>
         <div className='inputDiv'>
              <div className='cvrInput'>
                <h2>CVR</h2>
              <h3>{data.cvr}</h3>
              </div>

              <section >
              <div className='dateInput'>
                <h2>Sidst besøgt</h2>
                <h3>{new Date(apvs.allRecords[0]?.createdAt).toLocaleDateString('en-GB')}</h3>
              </div>
              
              <div className='clientInput'>
  <h2>Landmand</h2>
  <h3>{data.firstname} {data.middlename} {data.lastname}</h3>
</div>

<div className='emailInput'>
  <h2>Email</h2>
  <h3>{data.email}</h3>
</div>

<div className='phoneInput'>
  <h2>Telefonnummer</h2>
  <h3>{String(data.phone).replace(/(.)(?=(.{2})+$)/g, '$1 ')}</h3>
</div>

              </section>
              <div className='changedInput'>
  <h2>Sidst ændret</h2>
  <h2>Af</h2>
  <h3>{new Date(apvs.allRecords[0]?.updatedAt).toLocaleDateString('en-GB') || "Ingen data"}</h3>
  <h3>{apvs.findUser?.firstname || "Ingen data"} {apvs.findUser?.lastname}</h3>
</div>
</div>

<div className='problemDivs'>
                <section className='ProblemSec'>
                <h2>Kræver handling</h2>
                {
                    problems.length > 0 ? problems.map((item,index) =>(
                      <><h3>{item.question}</h3>
                      <div className='ProblemDiv'>
                      <p>{item.explanation}</p>
                      <p>{item.deadline}</p>
                      <br />
                      <button onClick={() => {
                        setModalIndex(index)
                        setModalOpen(true)
                        setIsProblem(true)
                      }}>?</button>
                      </div>
                      {/* <button onClick={() => moveToSolved(index)}>Marker som løst ⬆️</button> */}
                      
                </>
                    )) : <p>Ingen uløste opgaver</p>
                  }

                  
              </section>

              <section className='ProblemSec'>
                <h2>Løste opgaver</h2>
                {
                    solved.length > 0 ? solved?.map((item,index) =>(
                      <><h3>{item.question}</h3>
                      <div className='ProblemDiv'>
                      <p>{item?.explanation}</p>
                      <p>{item?.deadline}</p>
                      <br />
                      <button onClick={(e) => {
                        e.preventDefault()
                        setModalIndex(index)
                        setModalOpen(true)
                        setIsProblem(false)
                      }}>?</button>
                      </div>
                      {/* <button onClick={() => moveToSolved(index)}>Marker som løst ⬆️</button> */}
                      
                </>
                    )) : <p>Ingen løste opgaver</p>
                  }

                  
              </section>
</div>

<div>
  <section className='APVSec'>
                <h2>APV'er</h2>
                {
                  apvs.findUser ? apvs.allRecords.map((item,index) =>(
                      <div className='APVDiv'>
                  <p >{item.name}</p>
                </div>
                    ))
                 : <h2>Ingen apv'er er lavet</h2> }
              </section>

              <section key={bilag.length} className='bilagSec'>
                <h2>Bilag</h2>
                {bilag.length > 0 ?
                    bilag.map((item,index) =>(
                      <div className='bilagDiv'>
                  <p onClick={(e) => handleFileDownload(e)}>{item.filename}</p>
                </div>
                    ))
                  : <h2>Der ingen bilag endnu..</h2>}              
                  
                </section>
                <input className='uploadBtn' id="fileUpload" type="file" accept="image/*" onChange={(e) => handleImageUpload(e)}/>

                
              
</div>




              </> 
              
              : 
              
              <>
              <form onSubmit={handleSubmit(submitForm)}>
              <div>
                            <div className='cvrInput'>
                <h2>CVR</h2>
              <input type="number" name="cvr" id='cvr' placeholder='Skriv cvr nummer' {...register('cvr')}/>
              {errors.cvr && <span>Du skal skrive CVR nummeret!</span>}
              </div>
              <section >
              {/* <div className='dateInput'>
                <h2>Dato for besøg</h2>
              <input type="date" name="date" id='date' placeholder='Dato for besøg' {...register('date')}/>
              {errors.date && <span>Du skal angive datoen for besøg!</span>}
              </div> */}
              
              <div className='dateInput'>
                <h2>Sidst besøgt</h2>
                <h3>{new Date(apvs.allRecords[0]?.createdAt).toLocaleDateString('en-GB')}</h3>
              </div>
              <div className='clientInput'>
  <h2>Landmand</h2>
  <input 
    type="text" 
    name="client" 
    id='client' 
    placeholder='Navn på landmand' 
    value={client} // Bind input value to state
    onChange={handleClientChange} // Call sanitization function on change
    onBlur={handleClientChange} // Additional sanitization on blur
  />
  {errors.client && <span>Du skal skrive navnet på landmanden</span>}
</div>

<div className='emailInput'>
  <h2>Email</h2>
  <input 
    type="text" 
    name="email" 
    id='email' 
    placeholder='Email adresse' 
    value={email} // Bind input value to state
    onChange={handleEmailChange} // Call sanitization function on change
    onBlur={handleEmailChange} // Additional sanitization on blur
  />
  {errors.email && <span>Du skal skrive emailen</span>}
</div>

<div className='phoneInput'>
  <h2>Telefonnummer</h2>
  <input 
    type="tel" 
    name="phone" 
    id='phone' 
    placeholder='Telefonnummer' 
    value={phone} // Bind input value to state
    onChange={handlePhoneChange} // Call sanitization function on change
    onBlur={handlePhoneChange} // Additional sanitization on blur
  />
  {errors.phone && <span>Du skal skrive telefon nummeret</span>}
</div>

              </section>
              </div>

<div>
                <section className='ProblemSec'>
                <h2>Kræver handling</h2>
                {
                    problems.length > 0 ? problems.map((item,index) =>(
                      <><h3>{item.question}</h3>
                      <div className='ProblemDiv'>
                      <p>{item.explanation}</p>
                      <p>{item.deadline}</p>
                      </div>
                      <button onClick={() => moveToSolved(index)}>Marker som løst ⬆️</button>
                      
                </>
                    )) : <p>Ingen uløste opgaver</p>
                  }


                  
              </section>

              <section className='ProblemSec'>
                <h2>Løste opgaver</h2>
                {
                    solved.length > 0 ? solved.map((item,index) =>(
                      <><h3>{item.question}</h3>
                      <div className='ProblemDiv'>
                      <p>{item.explanation}</p>
                      <p>{item.deadline}</p>
                      </div>
                      
                      
                </>
                    )) : <p>Ingen løste opgaver</p>
                  }


                  
              </section>
</div>

<div>
                <section className='APVSec'>
                <h2>APV'er</h2>
                {
                  apvs.findUser ? apvs.allRecords.map((item,index) =>(
                      <div className='APVDiv'>
                  <p>{item.name}</p>
                  <div>
                    <button onClick={() => deleteAPV(item.id,item.name)}>Slet</button>
                    <button onClick={() => navigate(`/editapv/${item.id}`)}>Rediger</button>
                  </div>
                </div>
                    ))
                 : <h2>Ingen apv'er er lavet</h2> }
              </section>

              <section className='bilagSec'>
                <h2>Bilag</h2>
                {bilag.length > 0 ?
                    bilag.reverse().map((item,index) =>(
                      <div className='bilagDiv'>
                  <p id={"bilagItem" + index}>{item.filename}</p>
                  <div>
                  <button id={item.filename} onClick={(e) => handleFileDownload(e)}>Hent</button>
                                      <button onClick={(e) => {
                    bilag.splice(index, 1); 
                    console.log(bilag)

                    if (window.confirm("Er du sikker?")) {
                      handleFileDelete(e,index)
                    }
                    }} id={index}>Slet</button>
                  </div>

                </div>
                    ))
                  : <h2>Der ingen bilag endnu..</h2>}
                </section> 
                <input className='uploadBtn' id="fileUpload" type="file" accept="image/*" onChange={(e) => handleImageUpload(e)}/>

              </div>



<div></div>
<div></div>

              <article>
                <button className='clearAll' type='reset' onClick={() => {
                  setClient("")
                  setEmail("")
                  setPhone("")
                }} >Ryd alt</button>
                        <button type='submit' onClick={() => {
                          setTimeout(() => {
                            setEdit(false)
                          }, 200);
                          
                          
                          }}>Gem landmand</button>
                        
              </article>

            </form> 

              </>}
              {!edit ? <> 
                <div></div>
            <div></div>
              <article>
              
                <button className='clearAll' onClick={() => deleteFarmer(id)}>Slet landmand</button>
                        <button className='editBtn' onClick={(e) => {setEdit(true)}}>Rediger landmand </button>
                        {JSON.parse(sessionStorage.getItem(`tempPass${data.cvr}`)) ? <button onClick={() => sendLogin(data.email,id)}>Send login</button> : null}
                        
              </article></> : null}</>}
              {loading && (
        <Spinner />
      )}
        </ClientLookupStyle>
    );
}

export default ClientLookup;
