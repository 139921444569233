import React, { useEffect, useState } from 'react';
import { ClientPageStyle } from './ClientPage.style';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Spinner from '../../Components/Spinner/Spinner';

function ClientPage() {
  const { register, handleSubmit, formState: { errors } } = useForm()
  const navigate = useNavigate(); // Get the navigation function
  const [language, setLanguage] = useState("da");
  const [prevLanguage, setPrevLanguage] = useState("en");
  const [client, setClient] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [edit, setEdit] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);
  const [data, setData] = useState("");
  const [apvs, setApvs] = useState("");
  const { id } = useParams()
  const [loading, setLoading] = useState(false); // State for managing spinner visibility
  const [isProblem, setIsProblem] = useState(true);
  const [pageChange, setPageChange] = useState(0);
  const [sureDelete, setSureDelete] = useState(false);
    const [bilag, setBilag] = useState([])

    let APVs = [
      "APV1",
      "APV2",
      "APV3"
    ]

    const [problems, setProblems] = useState([]);
  
    const [solved, setSolved] = useState([]);


    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://api.kvægapv.eu/getFarmer/${id}`);
          const response1 = await axios.get(`https://api.kvægapv.eu/getSomeApv?id=${id}`);
          const response4 = await axios.get(`https://api.kvægapv.eu/getBilag/${id}`);
          setData((response.data));
          setApvs(response1.data);
          setBilag(response4.data.allBilag.reverse())
          console.log(response.data);
          console.log(response1.data);
          console.log(response4.data)
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
      
    }, []);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response4 = await axios.get(`https://api.kvægapv.eu/getBilag/${id}`);
          setBilag(response4.data.allBilag.reverse())
          console.log(response4.data)
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
    }, [pageChange])
    const moveToSolved = (index) => {
      const updatedProblems = [...problems];
      const movedItem = updatedProblems.splice(index, 1)[0];
      setProblems(updatedProblems);
      setSolved([...solved, movedItem]);
    };

    const handleImageUpload = event => {
      const file = event.target.files[0];
      const folder = "/" + data.id || '/';
      const clientId = id;
      if (!file) return;
    
      console.log('Uploading file:', file.name, 'to folder:', folder, "ClientID: ", clientId);
    
      const formData = new FormData();
      formData.append('file', file);
    
      fetch('https://api.kvægapv.eu/fileUpload', {
        headers: {
          'filename': file.name,
          'targetFolder': folder,
          'clientId': clientId
        },
        method: 'POST',
        body: formData
      })
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          // alert('File uploaded successfully');
          setPageChange(pageChange + 1) 
        } else {
          alert('Error uploading file: ' + data.description);
        }
      })
      .catch(error => {
        console.error('Upload error:', error);
        alert('Upload failed: ' + error.message);
      });
    };
    
    

    const handleFileDownload = (event) => {
      event.preventDefault();
      const filename = event.target.id;
      const folder = "/" + (data.id || '/');
      if (!filename) return;
  
      console.log('Downloading file:', filename, 'from folder:', folder);
  
      // Show spinner by setting loading to true
      setLoading(true);
  
      fetch(`https://api.kvægapv.eu/fileDownload?filename=${encodeURIComponent(filename)}&folder=${encodeURIComponent(folder)}`)
        .then(response => {
          console.log('Fetch response:', response);
          if (response.ok) {
            // alert("Din fil bliver nu hentet, nogle filer kan dog tage længere tid at hente end andre");
            return response.blob();
          } else {
            return response.json().then(data => {
              throw new Error(data.description);
            });
          }
        })
        .then(blob => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          a.remove();
          console.log('File downloaded successfully');
        })
        .catch(error => {
          console.error('Download error:', error);
          alert('Download failed: ' + error.message);
        })
        .finally(() => {
          // Hide spinner once the download is finished (either success or error)
          setLoading(false);
        });
    };

    
    const handleFileDelete = (event,index) => {

      event.preventDefault();
      const filename = document.getElementById('bilagItem' + index).innerText;
      const folder = data.id || '/';
      if (!filename) return;
    console.log(filename,folder);
      console.log('Deleting file:', filename, 'from folder:', folder);
    
      fetch(`https://api.kvægapv.eu/fileDelete?filename=${encodeURIComponent(filename)}&folder=${encodeURIComponent(folder)}&clientId=${encodeURIComponent(id)}`, {
        method: 'DELETE'
      })
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          // alert('File deleted successfully');
          setPageChange(pageChange + 1)
        } else {
          alert('Error deleting file: ' + data.description);
        }
      })
      .catch(error => {
        console.error('Delete error:', error);
        alert('Delete failed: ' + error.message);
      });
    };

    const translateText = async (text) => {
      try {
        const response = await axios.post('https://api.kvægapv.eu/translate', { text, targetLanguage: language, startLanguage: prevLanguage });
        return response.data.translatedText;
      } catch (error) {
        console.error('Translation error:', error);
        throw error;
      }
    };
  
    const translateWholePage = async () => {
      try {
        const textElements = Array.from(document.querySelectorAll('p, h1, h2, h3, h4, h5, h6, button'));
        for (let i = 0; i < textElements.length; i++) {
          const text = textElements[i].innerText;
          const translatedText = await translateText(text);
          textElements[i].innerText = translatedText;
        }
      } catch (error) {
        console.error('Translation error:', error);
      }
    };
console.log(solved);
    return (
        <ClientPageStyle edit={edit}>

 {!data && <h2 style={{position: "absolute", left: "38vw", top: "40vh"}}>Henter dine data...</h2>}
 {
         <div style={{display: modalOpen ? "block" : "none"}} className="modal">
          <button onClick={() => setModalOpen(modalOpen ? false : true)}>X</button>
         <div className="modal-content">
             <h2>{isProblem ? problems[modalIndex]?.question : solved[modalIndex].question}</h2>
             <div>
              <h4>Problemet</h4>
              <h4>Løses inden</h4>
              <h4>Ansvarlig</h4>
              <p>{isProblem ? problems[modalIndex]?.explanation : solved[modalIndex].explanation} </p>
              <p>{isProblem ? problems[modalIndex]?.deadline : solved[modalIndex].deadline}</p>
              <p>{isProblem ? problems[modalIndex]?.responsible : solved[modalIndex].responsible}</p>
             </div>
         </div>
       </div>
   }
{data && (<>
   <div> 
              <section >
              <div className='dateInput'>
                <h2>Sidst besøgt</h2>
                <h3>{new Date(apvs.allRecords[0]?.createdAt).toLocaleDateString('en-GB')}</h3>
              </div>
              
              <div className='clientInput'>
  <h2>Konsulent</h2>
  <h3>{apvs.findUser?.firstname} {apvs.findUser?.lastname}</h3>
</div>

<div className='emailInput'>
  <h2>Email</h2>
  <h3>{apvs.findUser?.email}</h3>
</div>

<div className='phoneInput'>
  <h2>Telefonnummer</h2>
  <h3>{String(apvs.findUser?.phone).replace(/(.)(?=(.{2})+$)/g, '$1 ')}</h3>
</div>

              </section>
              {/* <button onClick={translateWholePage}>Translate Page</button>
      <select onChange={(e) => {
        setPrevLanguage(language)
        setLanguage(e.target.value)
        
        }} name="" id="">
        <option value="en">🇬🇧</option>
        <option value="uk">🇺🇦</option>
        <option value="ro">🇷🇴</option>
        <option value="da">🇩🇰</option>
        <option value="pl">🇵🇱</option>
        <option value="es">🇪🇸</option>
        <option value="fr">🇫🇷</option>
        <option value="th">🇹🇭</option>
      </select> */}
   </div>

<div>
                <section className='ProblemSec'>
                <h2>Kræver handling</h2>
                {
                    problems.length > 0 ? problems.map((item,index) =>(
                      <><h3>{item.question}</h3>
                      <div className='ProblemDiv'>
                      <p>{item.explanation}</p>
                      <p>{item.deadline}</p>
                      <br />
                      <button onClick={() => {
                        setModalIndex(index)
                        setModalOpen(true)
                        setIsProblem(true)
                      }}>?</button>
                      </div>
                      <button onClick={() => moveToSolved(index)}>Marker som løst ⬆️</button>
                      
                </>
                    )) : <p>Ingen uløste opgaver</p>
                  }

                  
              </section>

              <section className='ProblemSec'>
                <h2>Løste opgaver</h2>
                {
                    solved.length > 0 ? solved.map((item,index) =>(
                      <><h3>{item.question}</h3>
                      <div className='ProblemDiv'>
                      <p>{item?.explanation}</p>
                      <p>{item?.deadline}</p>
                      <br />
                      <button onClick={() => {
                        setModalIndex(index)
                        setModalOpen(true)
                        setIsProblem(false)
                      }}>?</button>
                      </div>
                      {/* <button onClick={() => moveToSolved(index)}>Marker som løst ⬆️</button> */}
                      
                </>
                    )) : <p>Ingen løste opgaver</p>
                  }

                  
              </section>
              
</div>
     
<div>
<section className='APVSec'>
                <h2>APV'er</h2>
                {
                    apvs.allRecords.map((item,index) =>(
                      <div className='APVDiv'>
                  <p>{item?.name}</p>
                  
                    <button onClick={() => navigate(`/readapv/${item.id}`)}>Se den</button>
                  <p>{new Date(item?.createdAt).toLocaleDateString('en-GB')}</p>
                      
                </div>
                    ))
                  }
              </section>

              <section className='bilagSec'>
                <h2>Bilag</h2>
                {bilag.length > 0 ?
                    bilag.reverse().map((item,index) =>(
                      <div className='bilagDiv'>
                  <p id={"bilagItem" + index}>{item.filename}</p>
                  <div>
                  <button id={item.filename} onClick={(e) => handleFileDownload(e)}>Hent</button>
                                      <button onClick={(e) => {
                    bilag.splice(index, 1); 
                    console.log(bilag)

                    if (window.confirm("Er du sikker?")) {
                      handleFileDelete(e,index)
                    }
                    }} id={index}>Slet</button>
                  </div>

                </div>
                    ))
                  : <h2>Der ingen bilag endnu..</h2>}
                </section> 
                <input className='uploadBtn' id="fileUpload" type="file" accept="image/*" onChange={(e) => handleImageUpload(e)}/>

</div>
</>)}
{loading && (
        <Spinner />
      )}
        </ClientPageStyle>
    );
}

export default ClientPage;
