import React, { useEffect, useState } from 'react';
import { ReadOnlyAPVStyle } from './ReadOnlyAPV.style';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios'; // Import axios for making HTTP requests
import SuccesCheckmark from '../../Components/SuccesCheckmark/SuccesCheckmark';
import printImg from "../../Assets/9004704_printer_print_printing_office_work_icon.svg"

function ReadOnlyAPV(props) {
  const { id } = useParams()
  const navigate = useNavigate();
  const [num, setNum] = useState(0);
  const [newQuestion, setNewQuestion] = useState(null);
  const [textInput, setTextInput] = useState('');
  const [handleplanShow, setHandleplanShow] = useState([null,null]);
  const [translateError, setTranslateError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [language, setLanguage] = useState("en");
  const [prevLanguage, setPrevLanguage] = useState("en");
  const [hoverIndex, setHoverIndex] = useState(null);
  const [farmerId, setFarmerId] = useState(null);
  const [isPrint, setIsPrint] = useState(false);
  const [data, setData] = useState(null);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const [success, setSuccess] = useState(false);
console.log(id);
  const handleUpdate = () => {
    updateAPV(id,data)
    setHandleplanShow([null,null])
  }
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const addNewQuestion = (index) => {
    data[index].questions.push({ 
      question: textInput, 
      status: '', 
      deadline: "", 
      explanation: "", 
      problem: "", 
      responsible: ""});
    setNum(num + 1);
    setNewQuestion(null);
  };

  const deleteQuestion = (sectionIndex, questionIndex) => {
    const newData = [...data];
    newData[sectionIndex].questions.splice(questionIndex, 1);
    setData(newData);
  };

  const addStatus = (sectionIndex, questionIndex, status) => {
    const newData = [...data];
    newData[sectionIndex].questions[questionIndex].status = status;
    setData(newData);
    if (status === "Udfordring") {
          setHandleplanShow([sectionIndex, questionIndex])
    }

  };

  const addDeadline = (sectionIndex, questionIndex, deadline) => {
    const newData = [...data];
    newData[sectionIndex].questions[questionIndex].deadline = deadline;
    setData(newData);

  };

  const addExplantion = (sectionIndex, questionIndex, explanation) => {
    const newData = [...data];
    newData[sectionIndex].questions[questionIndex].explanation = explanation;
    setData(newData);

  };

  const addResponsible = (sectionIndex, questionIndex, responsible) => {
    const newData = [...data];
    newData[sectionIndex].questions[questionIndex].responsible = responsible;
    setData(newData);

  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.kvægapv.eu/getApv/${id}`);
        setData(response.data.content);
        setFarmerId(response.data.clientId);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    
    // Cleanup function
    return () => {
    
    };
  }, []);
  useEffect(() => {

      const h2Elements = Array.from(document.querySelectorAll('h2'));
    const optionElements = h2Elements.map((item, index) => (
      <p key={index} onClick={() => {document.getElementById(item.innerText.replace(' ', '-')).scrollIntoView({ behavior: 'smooth' }); setModalOpen(false)}}>{item.innerText}</p>
    ));
    setOptions(optionElements);

    
  }, [data]);

  const translateText = async (text) => {
    try {
      const response = await axios.post('https://lang.kvægapv.eu/translate', { text, targetLanguage: language, startLanguage: prevLanguage });
      return response.data.translatedText;
    } catch (error) {
      console.error('Translation error:', error);
      throw error;
    }
  };

  const translateAndAddQuestion = async (index) => {
    try {
      const translatedQuestion = await translateText(textInput);
      data[index].questions.push({ 
        question: translatedQuestion, 
        status: '', 
        deadline: "", 
        explanation: "", 
        problem: "", 
        responsible: "" 
      });
      setNum(num + 1);
      setNewQuestion(null);
    } catch (error) {
      // Handle translation error
      console.error('Translation error:', error);
      setTranslateError(true)
    }
  };

  const translateWholePage = async () => {
    try {
      const textElements = Array.from(document.querySelectorAll('p, h1, h2, h3, h4, h5, h6, button'));
      for (let i = 0; i < textElements.length; i++) {
        const text = textElements[i].innerText;
        const translatedText = await translateText(text);
        textElements[i].innerText = translatedText;
      }
    } catch (error) {
      console.error('Translation error:', error);
    }
  };
  const updateAPV = async (id,content) => {
    console.log(id);
    try {
        const response = await axios.put(`https://api.kvægapv.eu/updateapv/${id}`, {
            content: JSON.stringify(content), // Ensure content is stringified
        });
        //console.log('APV updated successfully:', response.data);
              setTimeout(() => {
        setSuccess(true); // After 1 second, set success to true
        setTimeout(() => {
          setSuccess(false); // After 1 second, set success to false
        }, 1500);
      }, 200);

    } catch (error) {
        console.error('Update error:', error);
        throw error;
    }
};
  return (
    <ReadOnlyAPVStyle>
      {success && <SuccesCheckmark />}
{ data ?      <>
<div className='btnDiv2'>
      <span id='theTop'></span>
      <button id='toTop' onClick={() => document.getElementById('theTop').scrollIntoView({ behavior: 'smooth' })}>⬆️</button>
      <button id='modalBtn' onClick={() => setModalOpen(modalOpen ? false : true)}>{'Hop til'}</button>

      </div>
      <div className='btnDiv'>
      {/* <button onClick={translateWholePage}>Translate Page</button>
      <select onChange={(e) => {
        setPrevLanguage(language)
        setLanguage(e.target.value)
        
        }} name="" id="">
        <option value="da">Dansk</option>
        <option value="pl">Polsk</option>
        <option value="es">Spansk</option>
        <option value="fr">Fransk</option>
        <option value="en">Engelsk</option>
        <option value="uk">Ukrainsk</option>
      </select> */}
      <button onClick={() => {
        document.getElementsByClassName('btnDiv2')[0].style.display = "none"
        document.getElementsByClassName('btnDiv')[0].style.display = "none"

        for (let index = 0; index < document.getElementsByClassName('headingArt').length; index++) {
          document.getElementsByClassName('headingArt')[index].style.gridTemplateColumns = "5% 55% 13% 13% 13%";
          
        }
        document.getElementsByTagName('header')[0].style.display = "none"
        document.getElementsByClassName('desktopForm')[0].style.width = "90vw"
        document.getElementsByClassName('desktopForm')[0].style.marginLeft = "5vw"
        document.getElementsByClassName('desktopForm')[0].style.marginTop = "2vw"
        setIsPrint(true)
        setTimeout(() => {
          window.print()
        }, 200);
        
        setTimeout(() => {
          document.getElementsByClassName('btnDiv2')[0].style.display = "block"
          document.getElementsByClassName('btnDiv')[0].style.display = "block"
          for (let index = 0; index < document.getElementsByClassName('headingArt').length; index++) {
            document.getElementsByClassName('headingArt')[index].style.gridTemplateColumns = "10% 50% 13% 13% 13%";
            
          }
          document.getElementsByTagName('header')[0].style.display = "block"
          document.getElementsByClassName('desktopForm')[0].style.width = "80vw"
          document.getElementsByClassName('desktopForm')[0].style.marginLeft = "12.5rem"
          setIsPrint(false)
        }, 200);
      }}> <img src={printImg} alt="Print" /> </button>
      

      </div>
      {modalOpen && (
        <div className="modal">
          <div className="modal-content">
            {options}
          </div>
        </div>
      )}
 
     { !isDesktop ? <section key={num} className='mobileForm'>
        {data.length < 0 ? data.map((item, index) => (
          <React.Fragment key={index}>
            <h2 id={item.heading.replace(' ', '-')}>{item.heading}</h2>
            {item.questions.map((question, qIndex) => (
              <div key={qIndex}>
                <p></p>
                <p>{question.question}</p>
                <button
                  style={{ backgroundColor: question.status === 'Udfordring' ? 'red' : 'initial' }}
                  
                >
                  Challange
                </button>
                <button
                  style={{ backgroundColor: question.status === 'I orden' ? 'green' : 'initial' }}
                  
                >
                  No problem
                </button>
                <button
                  style={{ backgroundColor: question.status === 'Irrelevant' ? 'gray' : 'initial' }}
                 
                >
                  Irrelevant
                </button>
              </div>
            ))}
          </React.Fragment>
        )) : <h2>Denne APV er tom</h2>}
      </section>
      :
<section key={num} className='desktopForm'>
  {data.map((item, index) => (
    <React.Fragment key={index}>
      <div onMouseOver={() => setHoverIndex(index)} onMouseLeave={() => setHoverIndex(null)}>
        <div className='categoryDiv' style={{display: data[index].questions.length > 0 ? "grid" : "none"}}>
          <h2 id={item.heading.replace(' ', '-')} style={{backgroundColor: "#D9D9D9", borderLeft: "solid black 1px"}}>{item.heading}</h2>
          <p style={{backgroundColor: "#CF4040"}} >Udfordring</p>
          <p style={{backgroundColor: "#28C253"}} >I orden</p>
          <p style={{backgroundColor: "#B8B8B8", borderRight: "solid black 1px"}} >Irrelevant</p>
        </div>
        {item.questions.map((question, qIndex) => (
          <React.Fragment key={`${index}-${qIndex}`}>
            <article className='headingArt'>
              <p className='deleteP'></p>
              <div style={{borderRight: "solid black 1px"}}>
                <span ><p>{question.question}</p><p>{isPrint ? " " : "?"}</p></span>
              </div>
              <div style={{borderRight: "solid black 1px"}}>{question.status === "Udfordring" ? "x" : ""}</div>
              <div style={{borderRight: "solid black 1px"}}>{question.status === "I orden" ? "x" : ""}</div>
              <div >{question.status === "Irrelevant" ? "x" : ""}</div>
            </article>
            <article className='handleplanArt' style={{display: index === handleplanShow[0] && qIndex === handleplanShow[1] ? "grid" : "none"}}>
              <div>
                <h3>Beskriv problemet</h3>
                <textarea name="" id="" cols="30" rows="5" defaultValue={question.explanation} onChange={(e) => addExplantion(index, qIndex, e.target.value)}></textarea>
              </div>
              <div className='deadlineDiv'>
                <h3>Deadline</h3>
                <div className='deadlineInputDiv'>
                  <label htmlFor="">Løses straks</label><input type="radio" name="" id="" defaultValue={question.deadline} onChange={(e) => addDeadline(index, qIndex, new Date().toLocaleDateString('en-GB'))}/> 
                  <label htmlFor="">Løses inden </label><input type="date" name="" id="" defaultValue={question.deadline} onChange={(e) => addDeadline(index, qIndex, new Date(e.target.value).toLocaleDateString('en-GB'))} />
                </div>
              </div>
              <div>
                <h3>Ansvarlig</h3>
                <input placeholder='Ansvarlig' type="text" defaultValue={question.responsible} onChange={(e) => addResponsible(index, qIndex, e.target.value)}/>
              </div>
            </article>
          </React.Fragment>
        ))}
      </div>
    </React.Fragment>
  ))}
</section>


}
     
      </> : <h2 style={{position: "absolute", left: "38vw", top: "40vh"}}>Henter apv'en...</h2>}
    </ReadOnlyAPVStyle>
  );
}

export default ReadOnlyAPV;
