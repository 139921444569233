import { styled } from "styled-components";

export const HeaderStyle = styled.header`
margin: 2vh 4vw;
background-color: #007297;
border-radius: 6px;
height: 5vh;
padding-top: 1vh;
padding-bottom: 1vh;
width: 10rem;

h1{
    text-align: center;
    font-weight: 500;
    color: white;
}

p{
        display: none;
    }

    button{
display: none;
    }
@media screen and (min-width: 768px) {
    margin: 1vh 1vw;
    padding: 1vh 0.5vw;
    height: 96vh;
    position: fixed;
    z-index: 1;
    }

    p{
        color: white;
        width: auto;
        display: block;
        text-align: center;
        margin-top: 2vh;
    }

    button{
        padding: 1vh 0;
        width: 10rem;
        border-radius: 6px;
        border: white solid 1px;
        background-color: #007297;
        position: absolute;
        bottom: 10px;
        color: white;
        display: block;
    }

    img{
        height: 8vh;
        margin-left: 2vw;
        margin-top: 43rem;
        
    }
    
`